const CONSUMER_TRUST_INDEX = 'CONSUMER_TRUST_INDEX';
const PAYEE_TRUST_INDEX = 'PAYEE_TRUST_INDEX';
const CHECKOUT_AND_REFUND = 'CHECKOUT_AND_REFUND';
const RETURNS_ORCHESTRATOR = 'RETURNS_ORCHESTRATOR';
const IDENTITY_AND_AUTH = 'IDENTITY_AND_AUTH';
const PAYMENT_PERSONALIZATION = 'PAYMENT_PERSONALIZATION';

export type config_type = 'LOW_RISK' | 'MEDIUM_RISK' | 'HIGH_RISK';

export const ORGANIZATION_TYPES = Object.freeze({
  NETWORK_MASTER: 'NETWORK_MASTER',
  PARTNER: 'PARTNER',
  NODE: 'NODE',
});

export const ROLES = Object.freeze({
  ADMIN: 'Admin',
  EDITOR: 'Editor',
  VIEWER: 'Viewer',
});

export const TransactionStatus = Object.freeze({
  Successful: 'Successful',
  Pending: 'Pending',
  Declined: 'Declined',
});

export const FraudRiskTypeStatus = Object.freeze({
  ACTIVE: 'ACTIVE',
  COMING_SOON: 'COMING_SOON',
});

export const PUBLIC_NAVIGATION: { [key: string]: string } = Object.freeze({
  login: '/login',
  verifySecureCode: '/verify-securecode',
  signUp: '/signup/:code',
  confirmEmail: '/confirm-email',
  signUpSuccess: '/signup-success',
  enrollment: '/enrollment',
  useCases: 'use-case',
  resetPassword: '/reset-password',
  resetEmailVerify: '/verify-reset-email',
  setNewPassword: '/set-new-password/:token',
  resetPasswordSuccess: '/reset-password-success',
  readmeDocs: '/readme-docs',
  projectInvite: '/project-invite/:token',
});

export const IMAGE_SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

export const FeaturesEnum = {
  Organization: 'Organisation',
  Users: 'Users',
  Roles: 'Roles',
  Projects: 'Projects',
  Dashboard: 'Dashboard',
};

export const rolePermissionEnum = {
  [FeaturesEnum.Organization]: {
    ViewPartner: 'View Partner',
    UpdatePartner: 'Update Partner',
    DeletePartner: 'Delete Partner',
    CreatePartner: 'Create Partner',
    CreateNode: 'Create Node',
    ViewNode: 'View Node',
    UpdateNode: 'Update Node',
    DeleteNode: 'Delete Node',
  },
  [FeaturesEnum.Users]: {
    CreateTeam: 'Create Team',
    ViewTeam: 'View Team',
    UpdateTeam: 'Update Team',
    DeleteTeam: 'Delete Team',
    CreateUser: 'Create User',
    ViewUser: 'View User',
    UpdateUser: 'Update User',
    DeleteUser: 'Delete User',
  },
  [FeaturesEnum.Projects]: {
    CreateProject: 'Create Project',
    ViewProject: 'View Project',
    UpdateProject: 'Update Project',
    DeleteProject: 'Delete Project',
  },
  [FeaturesEnum.Dashboard]: {
    ViewOrganizations: 'View organizations',
    ViewListOfConsumers: 'View list of consumers',
    ViewDashboard: 'View Dashboard',
  },
  [FeaturesEnum.Roles]: {
    CreateRole: 'Create Role',
    UpdateRole: 'Update Role',
    ViewRole: 'View Role',
    DeleteRole: 'Delete Role',
  },
};

export const PRIVATE_NAVIGATION = Object.freeze({
  consumerTrustGuide: {
    activeSidebar: -1,
    path: '/consumer-trust-index-guide',
    feature: true,
    permission: true,
    organization: true,
  },
  auth: {
    signup: {
      activeSidebar: 2,
      path: '/signup/:code',
      feature: true,
      permission: true,
      organization: true,
    },
    signupsuccess: {
      activeSidebar: 2,
      path: '/signup-success',
      feature: true,
      permission: true,
      organization: true,
    },
    projectinvite: {
      activeSidebar: 2,
      path: '/project-invite/:token',
      feature: true,
      permission: true,
      organization: true,
    },
  },
  useCase: {
    list: {
      activeSidebar: 2,
      path: '/use-case',
      feature: true,
      permission: true,
      organization: ORGANIZATION_TYPES.NODE,
    },
    product: {
      activeSidebar: 2,
      path: '/use-case/:product',
      feature: true,
      permission: true,
      organization: ORGANIZATION_TYPES.NODE,
    },
  },
  home: {
    activeSidebar: 1,
    path: '/home',
    feature: true,
    permission: true,
    organization: true,
  },
  users: {
    list: {
      activeSidebar: 3,
      path: '/users',
      feature: FeaturesEnum.Users,
      permission: rolePermissionEnum[FeaturesEnum.Users].ViewUser,
      organization: true,
    },
    view: {
      activeSidebar: 3,
      path: '/users/:id',
      feature: FeaturesEnum.Users,
      permission: rolePermissionEnum[FeaturesEnum.Users].ViewUser,
      organization: true,
    },
  },
  teams: {
    list: {
      activeSidebar: 3,
      path: '/teams',
      feature: true,
      permission: true,
      organization: true,
    },
    view: {
      activeSidebar: 3,
      path: '/teams/:id',
      feature: true,
      permission: true,
      organization: true,
    },
  },
  groups: {
    list: {
      activeSidebar: 3,
      path: '/groups',
      feature: true,
      permission: true,
      organization: ORGANIZATION_TYPES.NODE,
    },
    view: {
      activeSidebar: 3,
      path: '/groups:id',
      feature: true,
      permission: true,
      organization: ORGANIZATION_TYPES.NODE,
    },
    roles: {
      activeSidebar: 3,
      path: '/groups:id/roles',
      feature: true,
      permission: true,
      organization: ORGANIZATION_TYPES.NODE,
    },
  },
  projects: {
    list: {
      activeSidebar: 2,
      path: '/projects',
      feature: FeaturesEnum.Projects,
      permission: rolePermissionEnum[FeaturesEnum.Projects].ViewProject,
      organization: ORGANIZATION_TYPES.NODE,
    },
    add: {
      activeSidebar: 0,
      path: '/projects/new',
      feature: FeaturesEnum.Projects,
      permission: rolePermissionEnum[FeaturesEnum.Projects].CreateProject,
      organization: ORGANIZATION_TYPES.NODE,
    },
    edit: {
      activeSidebar: 0,
      path: '/projects/edit',
      feature: FeaturesEnum.Projects,
      permission: rolePermissionEnum[FeaturesEnum.Projects].UpdateProject,
      organization: ORGANIZATION_TYPES.NODE,
    },
    view: {
      activeSidebar: 0,
      path: '/projects/view',
      feature: FeaturesEnum.Projects,
      permission: rolePermissionEnum[FeaturesEnum.Projects].ViewProject,
      organization: ORGANIZATION_TYPES.NODE,
    },
    invite: {
      activeSidebar: 0,
      path: '/projects/invite',
      feature: FeaturesEnum.Projects,
      permission: rolePermissionEnum[FeaturesEnum.Projects].CreateProject,
      organization: ORGANIZATION_TYPES.NODE,
    },
    setting: {
      path: '/projects/settings',
      feature: FeaturesEnum.Projects,
      permission: rolePermissionEnum[FeaturesEnum.Projects].UpdateProject,
      organization: ORGANIZATION_TYPES.NODE,
    },
    sti: {
      getStarted: {
        activeSidebar: 0,
        path: '/projects/sti/get-started',
        feature: FeaturesEnum.Projects,
        permission: rolePermissionEnum[FeaturesEnum.Projects].CreateProject,
        organization: ORGANIZATION_TYPES.NODE,
      },
    },
    cr: {
      getStarted: {
        activeSidebar: 2,
        path: '/projects/cr/get-started',
        feature: FeaturesEnum.Projects,
        permission: rolePermissionEnum[FeaturesEnum.Projects].CreateProject,
        organization: ORGANIZATION_TYPES.NODE,
      },
    },
    configure: {
      activeSidebar: 0,
      path: '/projects/configure',
      feature: FeaturesEnum.Projects,
      permission: rolePermissionEnum[FeaturesEnum.Projects].CreateProject,
      organization: ORGANIZATION_TYPES.NODE,
    },
    manage: {
      activeSidebar: 2,
      path: '/projects/manage',
      feature: FeaturesEnum.Projects,
      permission: rolePermissionEnum[FeaturesEnum.Projects].ViewProject,
      organization: ORGANIZATION_TYPES.NODE,
    },
  },
  setupPayment: {
    list: {
      activeSidebar: 2,
      path: '/setup-payment',
      feature: true,
      permission: true,
      organization: ORGANIZATION_TYPES.NODE,
    },
    bankLogin: {
      activeSidebar: 2,
      path: '/setup-payment/bank-login',
      feature: true,
      permission: true,
      organization: ORGANIZATION_TYPES.NODE,
    },
    bankConsent: {
      activeSidebar: 2,
      path: '/setup-payment/bank-consent',
      organization: ORGANIZATION_TYPES.NODE,
      feature: true,
      permission: true,
    },
    setupSuccess: {
      activeSidebar: 2,
      path: '/setup-payment/setup-success',
      organization: ORGANIZATION_TYPES.NODE,
      feature: true,
      permission: true,
    },
    setupDeclined: {
      activeSidebar: 2,
      path: '/setup-payment/setup-declined',
      organization: ORGANIZATION_TYPES.NODE,
      feature: true,
      permission: true,
    },
  },
  organization: {
    list: {
      activeSidebar: 4,
      path: '/network',
      feature: FeaturesEnum.Organization,
      permission: `${rolePermissionEnum[FeaturesEnum.Organization].ViewPartner},${rolePermissionEnum[FeaturesEnum.Organization].ViewNode}`,
      organization: `${ORGANIZATION_TYPES.NETWORK_MASTER},${ORGANIZATION_TYPES.PARTNER}`,
    },
    add: {
      activeSidebar: 4,
      path: '/organization/create',
      feature: FeaturesEnum.Organization,
      permission: `${rolePermissionEnum[FeaturesEnum.Organization].CreatePartner},${rolePermissionEnum[FeaturesEnum.Organization].CreateNode}`,
      organization: `${ORGANIZATION_TYPES.NETWORK_MASTER},${ORGANIZATION_TYPES.PARTNER}`,
    },
    setup: {
      activeSidebar: -1,
      path: '/organization/setup',
      feature: true,
      permission: true,
      organization: true,
    },
    inviteAdmin: {
      activeSidebar: 4,
      path: '/organization/invite-admin',
      feature: FeaturesEnum.Organization,
      permission: `${rolePermissionEnum[FeaturesEnum.Organization].CreatePartner},${rolePermissionEnum[FeaturesEnum.Organization].CreateNode}`,
      organization: `${ORGANIZATION_TYPES.NETWORK_MASTER},${ORGANIZATION_TYPES.PARTNER}`,
    },
    invite: {
      activeSidebar: 4,
      path: '/organization/invite',
      feature: FeaturesEnum.Organization,
      permission: `${rolePermissionEnum[FeaturesEnum.Organization].CreatePartner},${rolePermissionEnum[FeaturesEnum.Organization].CreateNode}`,
      organization: `${ORGANIZATION_TYPES.NETWORK_MASTER},${ORGANIZATION_TYPES.PARTNER}`,
    },
  },
  contactSupport: {
    activeSidebar: 7,
    path: '/contact-support',
    feature: true,
    permission: true,
    organization: true,
  },
});

export {
  CHECKOUT_AND_REFUND,
  CONSUMER_TRUST_INDEX,
  IDENTITY_AND_AUTH,
  PAYEE_TRUST_INDEX,
  PAYMENT_PERSONALIZATION,
  RETURNS_ORCHESTRATOR,
};

export const DropDownOptions = Object.freeze({
  teams: [
    {
      label: 'General',
      value: 'General',
    },
    {
      label: 'Management',
      value: 'Management',
    },
  ],
  roles: [
    {
      label: 'Admin',
      value: 'Admin',
      description: 'Can fully configure and edit application',
    },
    {
      label: 'Editor',
      value: 'Editor',
      description: 'Can edit but cannot perform admin tasks',
    },
    {
      label: 'Viewer',
      value: 'Viewer',
      description: 'Can only view',
    },
  ],
  listBoxOptions: [
    {
      label: 'All',
      value: 'all',
    },
    {
      label: '/api/networksearch',
      value: '/api/networksearch',
    },
    {
      label: '/api/projects',
      value: '/api/projects',
    },
    {
      label: '/api/fraudprofiles',
      value: '/api/fraudprofiles',
    },
    {
      label: '/api/trudocs',
      value: '/api/trudocs',
    },
    {
      label: '/api/shoppertrustindex',
      value: '/api/shoppertrustindex',
    },
  ],

  DateRangeOptions: [
    {
      value: 30,
      label: 'Last 30 Days',
    },
    {
      value: 20,
      label: 'Last 20 Days',
    },
    {
      value: 15,
      label: 'Last 15 Days',
    },
    {
      value: 10,
      label: 'Last 10 Days',
    },
    {
      label: 'Last 5 Days',
      value: 5,
    },
  ],
  AllRecentOptions: [
    { value: 'all', label: 'All' },
    { value: 'recent', label: 'Recent' },
  ],
});

export const tabOptions = ['Organisation', 'Users', 'Projects', 'Dashboard', 'Roles'];

export const roleAndPermissionData: any = {
  [ROLES.ADMIN]: {
    organization: [
      {
        title: 'Create Partner',
        description: 'Add a new partner organisation.',
        hasPermission: false,
      },
      {
        title: 'View Partner',
        description: 'Browse through a partner organisation.',
        hasPermission: false,
      },
      {
        title: 'Update Partner',
        description: 'Make changes to a partner organisation.',
        hasPermission: false,
      },
      {
        title: 'Delete Partner',
        description: 'Permanently remove a partner organisation.',
        hasPermission: false,
      },
      {
        title: 'Create Node',
        description: 'Setup a new node organisation.',
        hasPermission: 'true',
      },
      {
        title: 'View Node',
        description: 'Browse through a node organisation.',
        hasPermission: 'true',
      },
      {
        title: 'Update Node',
        description: 'Make changes to a node organisation.',
        hasPermission: 'true',
      },
      {
        title: 'Delete Node',
        description: 'Permanently remove a node organisation.',
        hasPermission: 'true',
      },
    ],
    users: [
      {
        title: 'Create Team',
        description: 'Create a new team.',
        hasPermission: 'true',
      },
      {
        title: 'View Team',
        description: 'Browse through a team.',
        hasPermission: 'true',
      },
      {
        title: 'Update Team',
        description: 'Make changes to a team.',
        hasPermission: 'true',
      },
      {
        title: 'Delete Team',
        description: 'Permanently delete a team.',
        hasPermission: 'true',
      },
      {
        title: 'Create User',
        description: 'Create a new user.',
        hasPermission: 'true',
      },
      {
        title: 'View User',
        description: 'Browse through a user profile.',
        hasPermission: 'true',
      },
      {
        title: 'Update User',
        description: 'Make changes to a user.',
        hasPermission: 'true',
      },
      {
        title: 'Delete User',
        description: 'Permanently remove a user.',
        hasPermission: 'true',
      },
    ],
    projects: [
      {
        title: 'Create Project',
        description: 'Create a new project.',
        hasPermission: 'true',
      },
      {
        title: 'View Project',
        description: 'Browse through a project.',
        hasPermission: 'true',
      },
      {
        title: 'Update Project',
        description: 'Make changes to a project’s configuration.',
        hasPermission: 'true',
      },
      {
        title: 'Delete Project',
        description: 'Permanently delete a project.',
        hasPermission: 'true',
      },
    ],
    dashboard: [
      {
        title: 'View projects',
        description: 'Browse through projects.',
        hasPermission: 'true',
      },
      {
        title: 'View list of consumers',
        description: 'View consumers and their profiles on product tables.',
        hasPermission: 'true',
      },
    ],
    roles: [
      {
        title: 'Create Role',
        description: 'Create a new roles.',
        hasPermission: false,
      },
      {
        title: 'View Role',
        description: 'Browse through roles and permissions.',
        hasPermission: 'true',
      },
      {
        title: 'Update Role',
        description: 'Make changes to roles and permissions.',
        hasPermission: false,
      },
      {
        title: 'Delete Role',
        description: 'Permanently delete a role.',
        hasPermission: false,
      },
    ],
  },
  [ROLES.EDITOR]: {
    organization: [
      {
        title: 'Create Partner',
        description: 'Add a new partner organisation.',
        hasPermission: false,
      },
      {
        title: 'View Partner',
        description: 'Browse through a partner organisation.',
        hasPermission: false,
      },
      {
        title: 'Update Partner',
        description: 'Make changes to a partner organisation.',
        hasPermission: false,
      },
      {
        title: 'Delete Partner',
        description: 'Permanently remove a partner organisation.',
        hasPermission: false,
      },
      {
        title: 'Create Node',
        description: 'Setup a new node organisation.',
        hasPermission: false,
      },
      {
        title: 'View Node',
        description: 'Browse through a node organisation.',
        hasPermission: 'true',
      },
      {
        title: 'Update Node',
        description: 'Make changes to a node organisation.',
        hasPermission: 'true',
      },
      {
        title: 'Delete Node',
        description: 'Permanently remove a node organisation.',
        hasPermission: false,
      },
    ],
    users: [
      {
        title: 'Create Team',
        description: 'Create a new team.',
        hasPermission: 'true',
      },
      {
        title: 'View Team',
        description: 'Browse through a team.',
        hasPermission: 'true',
      },
      {
        title: 'Update Team',
        description: 'Make changes to a team.',
        hasPermission: 'true',
      },
      {
        title: 'Delete Team',
        description: 'Permanently delete a team.',
        hasPermission: 'true',
      },
      {
        title: 'Create User',
        description: 'Create a new user.',
        hasPermission: 'true',
      },
      {
        title: 'View User',
        description: 'Browse through a user profile.',
        hasPermission: 'true',
      },
      {
        title: 'Update User',
        description: 'Make changes to a user.',
        hasPermission: 'true',
      },
      {
        title: 'Delete User',
        description: 'Permanently remove a user.',
        hasPermission: 'true',
      },
    ],
    projects: [
      {
        title: 'Create Project',
        description: 'Create a new project.',
        hasPermission: 'true',
      },
      {
        title: 'View Project',
        description: 'Browse through a project.',
        hasPermission: 'true',
      },
      {
        title: 'Update Project',
        description: 'Make changes to a project’s configuration.',
        hasPermission: 'true',
      },
      {
        title: 'Delete Project',
        description: 'Permanently delete a project.',
        hasPermission: 'true',
      },
    ],
    dashboard: [
      {
        title: 'View projects',
        description: 'Browse through projects.',
        hasPermission: 'true',
      },
      {
        title: 'View list of consumers',
        description: 'View consumers and their profiles on product tables.',
        hasPermission: 'true',
      },
    ],
    roles: [
      {
        title: 'Create Role',
        description: 'Create a new roles.',
        hasPermission: false,
      },
      {
        title: 'View Role',
        description: 'Browse through roles and permissions.',
        hasPermission: 'true',
      },
      {
        title: 'Update Role',
        description: 'Make changes to roles and permissions.',
        hasPermission: false,
      },
      {
        title: 'Delete Role',
        description: 'Permanently delete a role.',
        hasPermission: false,
      },
    ],
  },
  [ROLES.VIEWER]: {
    organization: [
      {
        title: 'Create Partner',
        description: 'Add a new partner organisation.',
        hasPermission: false,
      },
      {
        title: 'View Partner',
        description: 'Browse through a partner organisation.',
        hasPermission: false,
      },
      {
        title: 'Update Partner',
        description: 'Make changes to a partner organisation.',
        hasPermission: false,
      },
      {
        title: 'Delete Partner',
        description: 'Permanently remove a partner organisation.',
        hasPermission: false,
      },
      {
        title: 'Create Node',
        description: 'Setup a new node organisation.',
        hasPermission: false,
      },
      {
        title: 'View Node',
        description: 'Browse through a node organisation.',
        hasPermission: 'true',
      },
      {
        title: 'Update Node',
        description: 'Make changes to a node organisation.',
        hasPermission: false,
      },
      {
        title: 'Delete Node',
        description: 'Permanently remove a node organisation.',
        hasPermission: false,
      },
    ],
    users: [
      {
        title: 'Create Team',
        description: 'Create a new team.',
        hasPermission: false,
      },
      {
        title: 'View Team',
        description: 'Browse through a team.',
        hasPermission: 'true',
      },
      {
        title: 'Update Team',
        description: 'Make changes to a team.',
        hasPermission: false,
      },
      {
        title: 'Delete Team',
        description: 'Permanently delete a team.',
        hasPermission: false,
      },
      {
        title: 'Create User',
        description: 'Create a new user.',
        hasPermission: false,
      },
      {
        title: 'View User',
        description: 'Browse through a user profile.',
        hasPermission: 'true',
      },
      {
        title: 'Update User',
        description: 'Make changes to a user.',
        hasPermission: false,
      },
      {
        title: 'Delete User',
        description: 'Permanently remove a user.',
        hasPermission: false,
      },
    ],
    projects: [
      {
        title: 'Create Project',
        description: 'Create a new project.',
        hasPermission: false,
      },
      {
        title: 'View Project',
        description: 'Browse through a project.',
        hasPermission: 'true',
      },
      {
        title: 'Update Project',
        description: 'Make changes to a project’s configuration.',
        hasPermission: false,
      },
      {
        title: 'Delete Project',
        description: 'Permanently delete a project.',
        hasPermission: false,
      },
    ],
    dashboard: [
      {
        title: 'View projects',
        description: 'Browse through projects.',
        hasPermission: 'true',
      },
      {
        title: 'View list of consumers',
        description: 'View consumers and their profiles on product tables.',
        hasPermission: 'true',
      },
    ],
    roles: [
      {
        title: 'Create Role',
        description: 'Create a new roles.',
        hasPermission: false,
      },
      {
        title: 'View Role',
        description: 'Browse through roles and permissions.',
        hasPermission: false,
      },
      {
        title: 'Update Role',
        description: 'Make changes to roles and permissions.',
        hasPermission: false,
      },
      {
        title: 'Delete Role',
        description: 'Permanently delete a role.',
        hasPermission: false,
      },
    ],
  },
};

export const ShopperConfigSequence = [
  'IDENTITY',
  'BANKING_HISTORY',
  'HISTORICAL_DATA',
  'NETWORK_INTELLIGENCE',
  'THIRD_PARTY_DATA',
];

export const webURL = `${window.location.protocol}//${window.location.host}`;
