import { useAxiosGet, useAxiosPost } from '@/hooks/useAxios';
import {
  getActiveOrganization,
  getParentOrganization,
  getUserOrganization,
  setActiveOrganization,
} from '@/redux/slice/authSlice';
import { setToken } from '@/redux/slice/tokenSlice';
import { ORGANIZATION_TYPES } from '@/utils/constants';
import { getClassNames, renderNameIcon } from '@/utils/helpers';
import { Dialog, Transition } from '@headlessui/react';
import { ReactComponent as CloseIcon } from '@icons/cancel.svg';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export interface organizationType {
  id: string;
  name: string;
  organization_type: string;
  image: string | null;
}

const SwitchOrganization = ({
  isOpen,
  closeModal,
  partnerOrganization,
  setPartnerOrganization,
}: {
  isOpen: boolean;
  closeModal: (val: boolean) => void;
  partnerOrganization: organizationType | null;
  setPartnerOrganization: (value: organizationType | null) => void;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [callApi] = useAxiosGet();
  const userOrganization = useSelector(getUserOrganization);
  const activeOrganization = useSelector(getActiveOrganization);
  const parentOrganization = useSelector(getParentOrganization);
  const [organizationList, setOrganizationList] = useState<organizationType[]>([]);

  const [finalOrganizationList, setFinalOrganizationList] = useState<organizationType[]>(
    []
  );
  const [activeOrganizationId, setActiveOrganizationId] = useState<string | null>(null);
  const [GetOrganizationList] = useAxiosGet();
  const [SwitchOrganizationApi] = useAxiosPost();
  useEffect(() => {
    const organizationListData = organizationList;
    activeOrganization?.id && setActiveOrganizationId(activeOrganization?.id);
    if (
      activeOrganization?.id &&
      activeOrganization?.name &&
      activeOrganization?.organization_type
    ) {
      organizationListData?.unshift({
        id: activeOrganization?.id,
        name: activeOrganization?.name,
        organization_type: activeOrganization?.organization_type,
        image: activeOrganization?.image ?? null,
      });
    }
    if (partnerOrganization) {
      organizationListData?.unshift(partnerOrganization);
    }
    if (
      !organizationList?.some(
        (organization) => organization?.id === userOrganization?.id
      ) &&
      userOrganization?.id &&
      userOrganization?.name &&
      userOrganization?.organization_type
    ) {
      organizationListData?.unshift({
        id: userOrganization?.id,
        name: userOrganization?.name,
        organization_type: userOrganization?.organization_type,
        image: userOrganization?.image ?? null,
      });
    }
    if (
      organizationListData?.length > 0 &&
      activeOrganization?.organization_type === ORGANIZATION_TYPES.NODE &&
      !organizationListData.find(
        (org) => org.organization_type === ORGANIZATION_TYPES.PARTNER
      ) &&
      parentOrganization?.id &&
      parentOrganization?.name &&
      parentOrganization?.organization_type
    ) {
      organizationListData.splice(1, 0, {
        id: parentOrganization?.id,
        name: parentOrganization?.name,
        organization_type: parentOrganization?.organization_type,
        image: parentOrganization?.image ?? null,
      });
    }
    setFinalOrganizationList(organizationListData);
  }, [organizationList, userOrganization]);

  useEffect(() => {
    const callApi = async () => {
      if (
        activeOrganization?.organization_type &&
        activeOrganization?.organization_type !== ORGANIZATION_TYPES.NODE
      ) {
        const organizationListArr: organizationType[] = [];
        const response = await GetOrganizationList('/organisation/get-sub-organisations');
        if (response?.data && response?.data?.length > 0) {
          response?.data?.map(
            (data: {
              id: string;
              name: string;
              organization_type: string;
              image: string | null;
            }) => {
              organizationListArr.push({
                id: data?.id,
                name: data?.name,
                organization_type: data?.organization_type,
                image: data?.image ?? null,
              });
            }
          );
        }
        if (organizationListArr?.length > 0) {
          setOrganizationList(organizationListArr);
        }
      }
    };
    callApi();
  }, [activeOrganization]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-20"
        onClose={() => {
          closeModal(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed top-20 left-0 right-0 ">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={getClassNames(
                  `w-full max-w-[600px] transform rounded-2xl bg-white text-left align-middle 
                shadow-[0px_4px_10px_rgba(0,0,0,0.05)] transition-all `
                )}
              >
                <div className="p-[32px]">
                  <Dialog.Title
                    as="div"
                    className={getClassNames(
                      'flex justify-between items-center text-base1 leading-xl',
                      'bg-white rounded-t-2xl mb-4'
                    )}
                  >
                    <h2 className="text-2xl text-body-black font-bold">
                      Switch organisation view
                    </h2>
                    <CloseIcon
                      onClick={() => closeModal(false)}
                      className="cursor-pointer"
                    />
                  </Dialog.Title>
                  <p className="text-base text-grey-content-tertiary font-medium">
                    Manage a different organisation by switching between views.
                  </p>
                </div>
                <div className="h-full max-h-[300px] mx-10 mb-8 border border-collapse border-stroke overflow-auto p-2">
                  {finalOrganizationList?.length > 0 &&
                    finalOrganizationList?.map((organization, index) => (
                      <div
                        key={index}
                        className="flex h-16 items-center hover:bg-grey-stroke-tertiary cursor-pointer"
                        onClick={async () => {
                          if (organization?.id !== activeOrganizationId) {
                            if (
                              organization.organization_type ===
                                ORGANIZATION_TYPES.NODE &&
                              activeOrganization
                            ) {
                              setPartnerOrganization({
                                id: activeOrganization.id ?? '',
                                name: activeOrganization.name ?? '',
                                organization_type:
                                  activeOrganization?.organization_type ?? '',
                                image: activeOrganization?.image ?? null,
                              });
                            } else if (partnerOrganization) {
                              setPartnerOrganization(null);
                            }
                            setActiveOrganizationId(organization?.id);
                            const resp = await SwitchOrganizationApi(
                              '/auth/switch-organisation',
                              {
                                organization_id: organization?.id,
                              }
                            );
                            if (resp?.data?.access_token) {
                              const activeOrganizationData = await callApi(
                                `/organisation/${organization?.id}`
                              );
                              if (activeOrganizationData?.data)
                                dispatch(
                                  setActiveOrganization(activeOrganizationData?.data)
                                );
                              dispatch(setToken({ token: resp?.data?.access_token }));
                              closeModal(false);
                              navigate('/');
                            }
                          }
                        }}
                      >
                        <div className="w-11 flex justify-center">{index + 1}</div>
                        <div className="flex justify-between w-full pl-6">
                          <div className="w-full flex justify-between">
                            <div>
                              {renderNameIcon({
                                name: organization?.name,
                                className:
                                  'bg-grey-content-quaternary text-white font-bold',
                                textClassName:
                                  'flex items-center ml-2 text-body-black font-medium text-base',
                                imageUrl: organization?.image ?? '',
                              })}
                            </div>
                            <div
                              className={getClassNames(
                                'flex items-center gap-x-1',
                                'mr-4 rounded-md border border-grey-stroke-primary px-2 py-1 h-[26px]'
                              )}
                            >
                              <div
                                className={getClassNames(
                                  'w-3 h-3 rounded-sm rounded-sm',
                                  `${organization?.organization_type === ORGANIZATION_TYPES.NETWORK_MASTER ? 'bg-trud-orange' : organization?.organization_type === ORGANIZATION_TYPES.PARTNER ? 'bg-tblue' : 'bg-green-alert'}`
                                )}
                              />
                              <span className="font-medium text-sm text-body-black">
                                {organization?.organization_type ===
                                ORGANIZATION_TYPES.NETWORK_MASTER
                                  ? 'Network Master'
                                  : organization?.organization_type ===
                                      ORGANIZATION_TYPES.PARTNER
                                    ? 'Partner'
                                    : 'Node'}
                              </span>
                            </div>
                          </div>
                          <div>
                            <div
                              className={`h-6 w-6 mr-3 border border-solid rounded-full flex justify-center items-center ${activeOrganizationId && activeOrganizationId === organization.id ? 'bg-blacked' : 'border-grey-content-tertiary'}  `}
                            >
                              {activeOrganizationId &&
                                activeOrganizationId === organization.id && (
                                  <div className="h-2 w-2 border border-solid rounded-full bg-body" />
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SwitchOrganization;
